<template>
  <v-card
    elevation="0"
    outlined
  >
    <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :value="header"
              @input="$emit('update:header', $event)"
              outlined
              dense
              :error-messages="checkErrors('detail.header')"
              label="Заголовок Н1"
            ></v-text-field>
            <v-text-field
              :value="title"
              @input="$emit('update:title', $event)"
              outlined
              dense
              :error-messages="checkErrors('detail.title')"
              label="Мета заголовок"
            ></v-text-field>
            <v-text-field
              :value="url"
              @input="$emit('update:url', $event)"
              outlined
              dense
              placeholder="Важно! '/' должен быть первым символом"
              :error-messages="checkErrors('detail.url')"
              label="Alias"
            ></v-text-field>
            <v-checkbox
              v-model="localIsPublished"
              @change="$emit('update:isPublished', $event)"
              :false-value="0"
              :true-value="1"
              label="Опубликовать"
              :disabled="!$can('admin')"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="meta_description"
              @input="$emit('update:meta_description', $event)"
              outlined
              dense
              :error-messages="checkErrors('detail.meta_description')"
              label="Мета описание"
            ></v-text-field>
            <v-text-field
              :value="meta_keywords"
              @input="$emit('update:meta_keywords', $event)"
              outlined
              dense
              :error-messages="checkErrors('detail.meta_keywords')"
              label="Ключевые слова"
            ></v-text-field>
            <v-textarea
              :value="content"
              @input="$emit('update:content', $event)"
              outlined
              label="Описание"
            ></v-textarea>
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import validationErrors from '@/components/mixins/validationErrors'

export default {
  mixins: [validationErrors],
  name: 'CmsSetting',
  props: {
    loading: Boolean,
    header: String,
    title: String,
    url: String,
    meta_description: String,
    meta_keywords: String,
    content: String,
    isPublished: {
      type: Number,
      default: 1
    },
    publishedAt: Number
  },
  data () {
    return {
      localIsPublished: this.isPublished
    }
  },
  watch: {
    isPublished (val) {
      let timestamp = null
      if (val) {
        timestamp = Math.round(Date.now() / 1000)
      }
      this.$emit('update:publishedAt', timestamp)
    }
  },
  inject: ['$v']
}
</script>

<style scoped>

</style>
