class Phone {
  constructor () {
    this.id = null
    this.phone = null
    this.is_agent = false
    this.name = null
    this.status = null
    this.call_history = []
    this.description = null
    this.owner_status = []
  }
}

export default Phone
