<template>
  <div class="text-center">
    <v-dialog
      fullscreen
      v-model="isOpen"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="white--text"
          small
          color="blue"
          v-on="on"
        >
          Настроить фильтр
        </v-btn>
      </template>
      <div class="d-flex">
        <DistrictSelect
          class="district-filter-block"
          :districtCustom.sync="compilation.districtCustom"
          :districtOfficial.sync="compilation.districtOfficial"
        />
        <v-container
          fluid
          class="white elevation-5 all-filter-block"
        >
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              icon
              @click="isOpen = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-row>
            <v-col cols="6">
              <DateRange
                v-model="compilation.touchDate"
                title="Диапазон дат касания"
              />
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="compilation.flatType"
                    :items="flatTypeList"
                    label="Тип жилья"
                    item-value="id"
                    item-text="value"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <div class="mr-0">
                    <v-select
                      v-model="compilation.buildingMaterial"
                      :items="buildingMaterialItems"
                      label="Материал стен"
                      item-value="id"
                      item-text="value"
                    />
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="mr-1">
                    <v-select
                      v-model="compilation.flatStatus"
                      :items="flatStatusItemsWithEmpty"
                      label="Состояние"
                      item-value="id"
                      item-text="value"
                      multiple
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <!--поиск по адресу-->
                <v-col cols="7">
                  <Autocomplete
                    v-model="compilation.streetFilter"
                    item-value="streetId"
                    item-text="name"
                    placeholder="Улица"
                    url="/street/autocomplete"
                    :chars-to-search="2"
                  />
                </v-col>
                <v-col cols="5">
                  <div class="flex-grow-0 mr-2">
                    <v-autocomplete
                      v-model="compilation.buildingNumberFilter"
                      :items="buildingNumbersWithEmpty"
                      label="Дом"
                      item-value="id"
                      item-text="building_number"
                      :loading="buildingLoading"
                      :disabled="!compilation.streetFilter"
                      @focus="fillBuildingNumbers"
                      return-object
                    ></v-autocomplete>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="mr-0">
                    <v-select
                      v-model="compilation.builder"
                      :items="builderItemsWithEmpty"
                      label="Застройщик"
                      item-value="id"
                      item-text="value"
                      multiple
                    />
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="mr-0">
                    <v-select
                      v-model="compilation.apartmentComplex"
                      :items="apartmentComplexItemsWithEmpty"
                      label="ЖК"
                      item-value="id"
                      item-text="value"
                      multiple
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-col cols="12">
                  <Slider
                    v-model="compilation.rooms"
                    :title="`Количество комнат от ${filterRanges.rooms[0]} до ${filterRanges.rooms[1]}`"
                    :range="filterRanges.rooms"
                  />
                </v-col>
                <v-col cols="12">
                  <Slider
                    v-model="compilation.buildingYear"
                    :title="`Год постройки от ${filterRanges.buildingYear[0]} до ${filterRanges.buildingYear[1]}`"
                    :range="filterRanges.buildingYear"
                  />
                </v-col>
                <v-col cols="12">
                  <Slider
                    v-model="compilation.floors"
                    :title="`Этажность<br>от ${filterRanges.floors[0]} до ${filterRanges.floors[1]}`"
                    :range="filterRanges.floors"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-col cols="12">
                  <Slider
                    v-model="compilation.floor"
                    :title="`Этаж от <br>${filterRanges.floor[0]} до ${filterRanges.floor[1]}`"
                    :range="filterRanges.floor"
                  />
                </v-col>
                <v-col cols="12">
                  <Slider
                    v-model="compilation.areaCommon"
                    :title="`Общая площадь <br>от ${filterRanges.areaCommon[0]} до ${filterRanges.areaCommon[1]}`"
                    :range="filterRanges.areaCommon"
                  />
                </v-col>
                <v-col cols="12">
                  <Slider
                    v-model="compilation.price"
                    :title="`Цена от ${filterRanges.price[0]} (тыс.рублей) до ${filterRanges.price[1]} (тыс.рублей)`"
                    :range="filterRanges.price"
                    input-style="width: 100px"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                small
                color="primary"
                class="mr-2"
                @click="handleCreate"
              >Создать
              </v-btn>
              <v-btn
                small
                @click="handleClear"
              >Очистить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Slider from '@/components/views/admin/advert/Common/Controls/Slider'
import { cleanClone, deepEqual } from '@/components/utils/common'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import DistrictSelect from '@/components/views/admin/advert/Common/Filters/DistrictSelect'
import formatters from '@/components/mixins/formatters'
import DateRange from '@/components/views/admin/advert/Common/Controls/DateRange'
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import AdvertFilterList from '@/components/structures/filters/advertFilterList'

export default {
  mixins: [serviceDataComputeds, formatters],
  components: { Slider, DistrictSelect, DateRange, Autocomplete },
  name: 'CmsFilter',
  props: {
    value: Object,
    active: Boolean,
    loading: Boolean
  },
  data () {
    return {
      buildingNumberList: [],
      isOpen: false,
      buildingLoading: false,
      filterRanges: {
        rooms: [0, 0],
        areaCommon: [0, 0],
        floor: [0, 0],
        floors: [0, 0],
        price: [0, 0],
        buildingYear: [0, 0]
      },
      compilation: new AdvertFilterList()
    }
  },
  watch: {
    'compilation.streetFilter': {
      handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal)) {
          this.compilation.buildingNumberFilter = null
          this.buildingNumberList = []
        }
      },
      deep: true
    },
    value: {
      handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal)) {
          this.compilation = cleanClone(this.value)
        }
      },
      immediate: true
    }
  },
  computed: {
    flatTypeList () {
      let result = this.flatTypeItems.filter((item) => item.id !== 7)
      return result
    }
  },
  methods: {
    async fillFilterDefaults () {
      const response = await this.$store.dispatch('table/getFilterDefaults', { url: '/advert/filter-defaults' })
      if (response) {
        this.filterRanges = cleanClone(response)
      }
      return true
    },
    handleCreate () {
      this.$emit('input', cleanClone(this.compilation))
      this.isOpen = false
    },
    handleClear () {
      this.compilation = new AdvertFilterList()
      this.$emit('input', cleanClone(this.compilation))
    },
    async fillBuildingNumbers () {
      if (this.compilation.streetFilter) {
        this.buildingLoading = true
        const response = await this.$store.dispatch('table/getServerData', { url: '/address/building-numbers?streetId=' + this.compilation.streetFilter.id })
        if (response) {
          this.buildingNumberList = response
        }
        this.buildingLoading = false
      }
    }
  },
  async created () {
    return this.fillFilterDefaults()
  }
}
</script>

<style scoped>
  .district-filter-block {
    width: 400px;
    position: relative;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: visible;
  }
  .all-filter-block {
    position: relative;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: visible;
  }
</style>
