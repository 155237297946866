import { recLookup } from '@/components/utils/common'

export default {
  methods: {
    checkErrors (propName) {
      const errors = []
      const validationObject = recLookup(this.$v, propName)
      if (!validationObject.$dirty) return errors
      if (validationObject.hasOwnProperty('required')) {
        !validationObject.required && errors.push('Необходимо заполнить поле')
      }
      if (validationObject.hasOwnProperty('minValue')) {
        !validationObject.minValue && errors.push('Поле должно быть больше 0')
      }
      if (validationObject.hasOwnProperty('email')) {
        !validationObject.email && errors.push('Введите корректный email')
      }
      if (validationObject.hasOwnProperty('minLength')) {
        !validationObject.minLength && errors.push('Поле должно иметь не менее ' + validationObject.$params.minLength.min + ' символов')
      }
      if (validationObject.hasOwnProperty('sameAsPassword')) {
        !validationObject.sameAsPassword && errors.push('Поле должно совпадать с новым паролем')
      }
      if (validationObject.hasOwnProperty('isUnique')) {
        !validationObject.isUnique && !validationObject.$pending && errors.push('Поле должно быть уникальным')
      }
      if (validationObject.hasOwnProperty('isUniqueUrl')) {
        !validationObject.isUniqueUrl && !validationObject.$pending && errors.push('Alias должен быть уникальным')
      }
      if (validationObject.hasOwnProperty('url')) {
        !validationObject.url && errors.push('Введите корректный alias(Первый символ "/",</br> без пробелов,</br> на латинице,</br> слова через "-" или "_"')
      }
      return errors
    },
    async checkPending () {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          if (this.$v.$pending) {
            await this.checkPending()
          }
          resolve(true)
        }, 2000)
      })
    }
  }
}
